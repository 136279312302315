<template>
  <div>
    <div class="wrapper">
      <div class="input-item mb-btn">
        <van-field
          v-model="name"
          label=""
          placeholder="请输入姓名"
          maxlength="11"
        >
        </van-field>
      </div>
      <div class="input-item mb-btn">
        <van-field
          v-model="phone"
          label=""
          placeholder="请输入手机号"
          maxlength="11"
        >
        </van-field>
      </div>
      <div class="input-item mb-btn-2">
        <van-field
          v-model="captcha"
          label=""
          maxlength="6"
          placeholder="请输入验证码"
        >
          <template #button>
            <span
              class="send-text"
              @click="sendVerify()"
              :class="{ 'disable-text': countDown.status === 'run' }"
            >
              {{
                countDown.status === 'run'
                  ? `${countDown.duration}s后重发`
                  : '发送验证码'
              }}
            </span>
          </template>
        </van-field>
      </div>
      <van-button class="confirm" @click="submit">确认</van-button>
      <van-button class="reg" @click="cancel">取消</van-button>
    </div>
  </div>
</template>
<script>
import { sendSMS, registerCode } from '@/api/login'
export default {
  data() {
    return {
      name: '',
      phone: '',
      captcha: '',
      phoneReg: new RegExp(
        /^1(3\d|4[5-9]|5[0-35-9]|6[567]|7[0-8]|8\d|9[0-35-9])\d{8}$/
      ),
      countDown: {
        timeout: false,
        timer: null,
        duration: 60,
        status: 'stop',
        start: () => {
          this.countDown.timer =
            this.countDown.timer ||
            setInterval(() => {
              this.countDown.status = 'run'
              this.countDown.duration -= 1
              if (this.countDown.duration <= 0) {
                this.countDown.stop()
              }
            }, 1000)
        },
        stop: () => {
          clearInterval(this.countDown.timer)
          this.countDown.timer = null
          this.countDown.status = 'stop'
          this.countDown.duration = 60
        },
      },
    }
  },
  created() {},
  computed: {},
  methods: {
    sendVerify() {
      // 发送验证码
      if (this.countDown.timeout) {
        return
      }
      this.countDown.timeout = true

      if (this.countDown.status === 'run') return

      if (!this.phone) {
        this.$toast.fail('请输入手机号')
        return
      } else if (!this.phoneReg.test(this.phone)) {
        this.$toast.fail('请输入正确的手机号')
        return
      }

      const params = {
        mobile: this.phone,
        smsmode: '1',
      }

      sendSMS(params).then((res) => {
        this.countDown.timeout = false
        if (res && res.success) {
          this.$toast.success('验证码发送成功，请查收')
          this.countDown.start()
        }
      })
    },
    async submit() {
      if (!this.phone) {
        this.$toast.fail('请输入手机号')
        return
      } else if (!this.phoneReg.test(this.phone)) {
        this.$toast.fail('请输入正确的手机号')
        return
      } else if (!this.captcha) {
        this.$toast.fail('请输入验证码')
        return
      } else if (!this.name) {
        this.$toast.fail('用户名')
      }
      const params = {
        mobile: this.phone,
        captcha: this.captcha,
        name: this.name,
      }
      registerCode(params).then((res) => {
        console.log(res)
        if (res.code == 200) {
          this.$toast.success('注册成功')
          this.$router.push({
            path: '/login',
          })
        } else {
          this.$toast.fail('注册失败')
        }
      })
    },
    cancel() {
      this.$router.push({
        path: '/login',
      })
    },
  },
}
</script>
<style lang="less" scoped>
.wrapper {
  background-color: #fff;
  padding: 240px 56px 120px;
  margin: 0 62px;
  margin-top: 300px;
}
.send-text {
  color: @primary-color;
}
.disable-text {
  color: @primary-font-color;
}
.mb-btn {
  margin-bottom: 24px;
}
.mb-btn-2 {
  margin-bottom: 112px;
}
.confirm {
  width: 100%;
  color: #fff;
  font-size: 56px;
  height: 162px;
  border-radius: 80px;
  background: linear-gradient(
    to right,
    rgba(255, 137, 28, 1),
    rgba(255, 85, 8, 1)
  );
}
.reg {
  width: 100%;
  color: black;
  font-size: 56px;
  height: 162px;
  border-radius: 80px;
  background-color: white !important;
  border: rgba(255, 85, 8, 1) 2px solid;
  margin-top: 60px;
}
.input-item {
  background-color: #f7f7fa;
  border-radius: 80px;
  height: 162px;
  padding: 0 20px;
  display: flex;
  align-items: center;
}
</style>
